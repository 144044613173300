import { useActor } from '@xstate/react';
import { CredentialsForm } from '@client/components/credentials-form';
import { VerifyingCredentials } from '@client/components/verifying-credentials';
import { MfaVerificationMethodForm } from '@client/components/mfa-verification-method-form';
import { MfaVerificationCodeForm } from '@client/components/mfa-verification-code-form';
import { MfaFailureScreen } from '@client/components/mfa-failure/mfa-failure.component';
import { MFAUnsupportedScreen } from '@client/components/mfa-unsupported-screen';
import { SmtMeterForm } from '@client/components/smt-meter-form';
import { MeterConclusion } from '@client/components/meter-conclusion';
import { CredentialsMachineActor } from './types';

export type Props = {
  credentialsMachineRef: CredentialsMachineActor;
};

export const CredentialsScreens = ({ credentialsMachineRef }: Props): JSX.Element | null => {
  const [credentialsState, send] = useActor(credentialsMachineRef);
  const { context: credentialsContext } = credentialsState;

  // refresh flow skips the credentials form and goes straight to polling
  const isRefreshFlow = !!credentialsContext.config.refreshTokenInfo;

  const showCorrelationIdField = credentialsContext.config.allowSettingCorId;

  const allowProviderReselect =
    !credentialsContext.config.providerDetails &&
    !credentialsContext.config.updateTokenInfo &&
    !credentialsContext.config.refreshTokenInfo;
  if (credentialsState.matches('credentialsForm') && !isRefreshFlow)
    return (
      <CredentialsForm
        organization={credentialsContext.organization}
        templateFields={credentialsContext.templateFields}
        acquisitionTemplate={credentialsContext.acquisitionTemplate}
        provider={credentialsContext.provider}
        pending={credentialsState.matches('credentialsForm.submitting')}
        onSubmit={params => send({ type: 'SUBMIT_CREDENTIALS', data: params })}
        showCorrelationIdField={showCorrelationIdField}
        error={credentialsContext.credentialsError}
      />
    );
  if (credentialsState.matches('metersForm')) {
    return (
      <SmtMeterForm
        organization={credentialsContext.organization}
        provider={credentialsContext.provider}
        onSubmit={params => send({ type: 'SUBMIT_METER', data: params })}
        error={credentialsContext.credentialsError}
        pending={credentialsState.matches('metersForm.submitting')}
      ></SmtMeterForm>
    );
  }
  if (
    credentialsState.matches('polling') ||
    (credentialsState.matches('credentialsForm') && isRefreshFlow) ||
    credentialsState.matches('credentialsConclusion')
  )
    return (
      <VerifyingCredentials
        provider={credentialsContext.provider}
        organization={credentialsContext.organization}
        status={credentialsContext.challengeStatus}
        complete={
          credentialsState.matches('polling.verifiedTransitioning') ||
          credentialsState.matches('credentialsConclusion')
        }
      />
    );
  if (
    credentialsState.matches('mfaVerificationMethodsForm') &&
    credentialsContext.returnedVerificationMethods
  )
    return (
      <MfaVerificationMethodForm
        provider={credentialsContext.provider}
        mfaVerificationMethods={credentialsContext.returnedVerificationMethods}
        onSubmit={({ selectedVerificationMethod }) =>
          send({ type: 'SUBMIT_METHOD', data: selectedVerificationMethod })
        }
        onProviderReselect={allowProviderReselect ? () => send('RESELECT_PROVIDER') : undefined}
        pending={credentialsState.matches('mfaVerificationMethodsForm.submitting')}
        error={credentialsContext.credentialsError}
      />
    );
  if (credentialsState.matches('mfaVerificationCodeForm'))
    return (
      <MfaVerificationCodeForm
        selectedVerificationMethod={credentialsContext.selectedVerificationMethod}
        onSubmit={({ verificationCode }) => send({ type: 'SUBMIT_CODE', data: verificationCode })}
        provider={credentialsContext.provider}
        pending={credentialsState.matches('mfaVerificationCodeForm.submitting')}
        submissionError={credentialsContext.credentialsError}
        isResubmission={credentialsContext.isResubmission}
        onResendCode={() => send('RESEND_CODE')}
        onMethodReselect={() => send('RESELECT_METHOD')}
      />
    );
  if (credentialsState.matches('mfaFailure')) {
    return (
      <MfaFailureScreen
        provider={credentialsState.context.provider}
        onProviderReselect={allowProviderReselect ? () => send('RESELECT_PROVIDER') : undefined}
      ></MfaFailureScreen>
    );
  }
  if (credentialsState.matches('mfaUnsupported')) {
    return (
      <MFAUnsupportedScreen
        provider={credentialsState.context.provider}
        onProviderReselect={allowProviderReselect ? () => send('RESELECT_PROVIDER') : undefined}
      />
    );
  }
  if (credentialsState.matches('metersConclusion')) {
    return <MeterConclusion></MeterConclusion>;
  }
  throw new Error('Unknown state');
};

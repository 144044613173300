import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Copy = styled(Text)`
  text-align: center;
  white-space: pre-wrap;
`;

export const LoadingAnimationStyle = {
  height: '40vh',
  width: '500px',
  maxWidth: '90vw',
  margin: '0 auto',
};

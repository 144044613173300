export const en = {
  connect: {
    standaloneWrapper: {
      errors: {
        generic: 'Something went wrong',
        noOrgId: 'Connect requires an organization identifier in the URL',
        invalidUsecase:
          'This use-case is not supported for your organisation. Reach out to customer support or your dedicated account manager to enable this.',
      },
    },
    screenTitles: {
      default: 'Connect your utility',
      provider: 'Select your utility',
      connectAccount: 'Enter credentials',
      reconnectAccount: 'Reconnect your account',
      polling: 'Connecting with your utility',
      mfaMethodSelection: 'Select a verification method',
      mfaCodeEntry: 'Enter verification code',
      conclusionSuccess: 'Account connected',
      conclusionReceived: 'Credentials received',
      invalidMfa: 'Connection error',
      submitMeter: 'Enter information',
      conclusionSubmitMeter: 'Connection pending',
      selectDataAccess: 'Select data access',
    },
    errors: {
      incorrectCredentials: 'Your credentials are incorrect.',
      genericError: 'Something went wrong. Please try again.',
      duplicateCredentialsError: 'There is already a user with these utility credentials.',
      duplicateMetersError: 'There is already a meter with these inputs',
      noProviderError: 'You must select a provider to continue.',
      rateLimitExceededError: 'Too many credential submissions. Try again later.',
    },
    components: {
      connectContainer: {
        backButton: 'Go back',
      },
      disclaimer: {
        text: 'Arcadia uses industry-standard encryption and never shares your data without your permission. Arcadia’s data platform is a service of Arcadia Power, Inc., which is a service provider independent of, and not affiliated with, your utility. Your utility has not endorsed this service.',
      },
      forgotPasswordLink: {
        description: 'Having trouble logging in?',
        linkLabel: 'Retrieve your {{providerName}} credentials',
      },
      mfaFailure: {
        expired: 'Your verification code expired.',
        error: "We couldn't verify your {{providerName}} account.",
        selectDescription: 'Select one option below to try again',
        reselectUtility: 'Select another utility',
        reselectVerificationMethod: 'Select another verification method',
        resendCode: 'Resend verification code',
      },
      mfaUnsupportedScreen: {
        selectButton: 'Select another utility',
        description: {
          firstLine:
            '{{providerName}} requires two-factor authentication, which we do not yet support for this utility.',
          secondLine: 'We are working to enable this feature for all providers soon.',
          reselectProvider: ' Please select another utility.',
        },
      },
      mfaVerificationCodeForm: {
        description: {
          call: '{{providerName}} has sent a code to your <bold>phone number {{verificationMethod}}</bold> - This could take a few minutes.',
          email:
            '{{providerName}} has sent a code to your <bold>email address {{verificationMethod}}</bold> - This could take a few minutes.',
          text: '{{providerName}} has sent a code to your <bold>phone number {{verificationMethod}}</bold> - This could take a few minutes.',
          other:
            '{{providerName}} has sent a code to <bold>{{verificationMethod}}</bold> - This could take a few minutes.',
          methodNameUndefined:
            '{{providerName}} has sent a code to your selected verification method - This could take a few minutes.',
          enterCode:
            'Please enter the code below to verify your identity and finish connecting your account.',
        },
        inputs: {
          verificationCode: 'Verification code',
          submitButton: 'Submit',
        },
        validations: {
          fieldRequiredErrorMessage: '{{fieldName}} is required',
        },
        errors: {
          invalidCode: 'Your code is incorrect. Please try again.',
          server: 'There was a problem. Please try again later or contact us for help.',
        },
        resendCode: {
          description: "Didn't get a code?",
          link: 'Resend verification code',
        },
        reselectMethod: {
          description: "Can't access your code?",
          link: 'Select another verification method',
        },
      },
      organizationTerms: {
        preTermsText: 'By continuing you agree to {{organizationName}}’s',
        regularJoiner: ', ',
        lastJoiner: ' and ',
        ender: '.',
        termsOfServiceLabel: 'Terms of Service',
        privacyPolicyLabel: 'Privacy Policy',
      },
      providerForm: {
        headerText:
          '{{organizationName}} uses Arcadia Platform to connect to your utility account and retrieve utility data.',
        tooltipText: 'What data is captured?',
        tooltipContent1:
          'Arcadia uses industry-standard encryption and never shares your data without your permission.',
        tooltipContent2:
          'Connecting your account gathers the following data: Utility account number, service address, service type (electric, gas, etc.), bill amounts, due dates, monthly energy consumption, service dates, electricity rate, and an hourly energy usage profile as reported by your smart meter.',
        searchLabel: 'Search by provider name',
        noProvidersText: 'No results found for "<0>{{providerSearchString}}</0>".',
        refineSearchText: 'Please refine your search to continue.',
        continueButton: 'Continue',
        submissionError: 'Something went wrong searching',
        defaultError: 'Something went wrong. Please try submitting your credentials again',
      },
      credentialsForm: {
        description:
          'Please enter your {{providerName}} login credentials. We always keep your data safe and secure.',
        descriptionThirdPartyPortal:
          'Find your account number on a recent bill or by logging into your ConEd account and navigating to Account & Billing > My Account > Manage My Account.',
        partialLabel: '{{label}} Part {{position}}',
        correlationIdLink: 'Learn more about Correlation IDs',
        validations: {
          fieldRequiredErrorMessage: '{{fieldName}} is required',
          fieldLengthErrorMessage: '{{fieldName}} must be {{length}} characters long',
          fieldConfirmationFailsErrorMessage: 'Values must match',
          fieldConEdAccountNumberErrorMessage:
            'Please enter the 11-digit account number that was assigned to your ConEd account as of October, 2023',
        },
        inputs: {
          username: 'Username',
          password: 'Password',
          submitButton: 'Submit',
          resubmitButton: 'Update connection',
          correlationId: 'Correlation ID (optional)',
        },
      },
      mfaVerificationMethodForm: {
        description:
          '{{providerName}} uses two-factor authentication to secure your account. How would you like to receive your verification code?',
        methodTitle: 'Verification method',
        methodType: {
          text: 'Text {{methodName}}',
          email: 'Email {{methodName}}',
          call: 'Call {{methodName}}',
          other: 'Other {{methodName}}',
        },
        disclaimer: 'Texting and data rates may apply.',
        selectUtility: 'Select another utility',
        unableToAuthenticate: 'Unable to authenticate at this moment?',
        submitButton: 'Send verification code',
        validations: {
          verificationMethodRequired: 'You must select a verification method',
        },
      },
      smtMeterForm: {
        retailCustomerEmail: 'Email address',
        meterNumber: 'Meter number',
        electricServiceIdentifier: 'ESI ID',
        submit: 'Submit',
        description:
          'Please refer to a recent bill from {{providerName}} to help you fill in the details below. We always keep your data safe and secure.',
        validations: {
          fieldRequiredErrorMessage: '{{fieldName}} is required',
          fieldValidEmailErrorMessage: 'Email address must be a valid email',
          fieldLengthErrorMessage: 'ESI ID must be between 17 and 22 characters long',
        },
      },
      smtMeterConclusion: {
        description:
          'In the next 5 - 10 minutes, you will receive an email from Smart Meter Texas (SMT) containing a link. Please click this link to grant Arcadia access to granular meter data.',
      },
      selectDataAccess: {
        submit: 'Submit',
        description:
          "Choose the type of data you'd like to share with Arcadia. You can always share more data later.",
        monthlyTitle: 'Monthly bill data',
        monthlyDescription:
          'Connect your utility account through online login credentials to give Arcadia access to billing data',
        intervalsTitle: 'Granular meter readings',
        intervalsDescription:
          'Provide utility bill information to grant Arcadia access to meter readings through ',
        smartMeterTexas: 'Smart Meter Texas',
      },
      verifiying: {
        verifiyingText:
          'Establishing a secure connection to {{providerName}}.\nThis should take less than a minute.',
      },
      conclusion: {
        loginSuccess:
          'Your {{providerName}} account has been authorized and your utility data is now connected!',
        loginTimedOut:
          'Arcadia is checking this credential with {{providerName}}.\nYou’re all set – no further action is required.',
        thirdPartyPortal:
          'In the next 2-3 minutes, you will receive an email from ConEd containing a link. Please click on the link to confirm you are granting access to your utility data.',
      },
    },
  },
};

import { useCallback, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { ProviderForm } from '@client/components/provider-form';
import { assertNotNullable, Organization } from '@arc-connect/schema';
import { Config } from '@client/types/config';
import { ConnectContainer } from '@client/components/connect-container';
import { trpc } from '@client/utils/trpc';
import { useTranslation } from 'react-i18next';
import { SelectDataAccessComponent } from '@client/components/select-data-access';
import { CredentialsScreens } from '../credentials/credentials-screens';
import { connectMachine } from './connect';

export interface Props {
  organization: Organization;
  config: Config;
}

export const ConnectScreens = ({ organization, config }: Props): JSX.Element | null => {
  const { t } = useTranslation('connect', { keyPrefix: 'screenTitles' });

  const { client } = trpc.useContext();

  const [connectState, send] = useMachine(() => connectMachine({ client, organization, config }));
  const { credentialsMachine } = connectState.context;

  const title = useMemo(() => {
    // let result = 'default';
    // if we're on the provider screen, return that title
    if (connectState.matches('provider')) {
      return t('provider');
    }
    // if they're selecting statements vs intervals data access
    if (connectState.matches('dataAccessSelection')) {
      return t('selectDataAccess');
    }
    // if we're in the credentials machine, look to see if we're polling
    if (connectState.matches('credentialsMachine') && credentialsMachine) {
      const snapshot = credentialsMachine.getSnapshot();

      if (
        snapshot?.matches('polling') ||
        (snapshot?.matches('credentialsForm.submitting') &&
          !!connectState.context.config.refreshTokenInfo)
      )
        return t('polling');
      else if (snapshot?.matches('mfaVerificationMethodsForm')) return t('mfaMethodSelection');
      else if (snapshot?.matches('mfaVerificationCodeForm')) return t('mfaCodeEntry');
      else if (snapshot?.matches('mfaFailure')) return t('invalidMfa');
      else if (snapshot?.matches('metersForm')) return t('submitMeter');
      else if (snapshot?.matches('metersConclusion')) return t('conclusionSubmitMeter');
      else if (snapshot?.matches('credentialsConclusion')) {
        return connectState.context.challengeStatus === 'LOGIN_SUCCESS'
          ? t('conclusionSuccess')
          : t('conclusionReceived');
      } else {
        // check whether we're in update or create flow
        const isReconnectFlow = !!(
          connectState.context.config.updateTokenInfo ||
          connectState.context.config.refreshTokenInfo
        );
        return isReconnectFlow ? t('reconnectAccount') : t('connectAccount');
      }
    }

    return t('default');
  }, [connectState, credentialsMachine, t]);

  const previous = useMemo(() => {
    if (credentialsMachine) {
      if (credentialsMachine.getSnapshot()?.can('PREVIOUS'))
        return () => credentialsMachine.send('PREVIOUS');
    } else if (connectState.can('PREVIOUS')) {
      return () => send('PREVIOUS');
    }
  }, [credentialsMachine, connectState, send]);

  const renderScreen = useCallback(() => {
    if (connectState.matches('provider'))
      return (
        <ProviderForm
          initialProviderSearch={connectState.context.providerSearchString}
          pending={connectState.matches('provider.fetchingAcquisitionTemplate')}
          submissionError={connectState.context.acquisitionTemplateError}
          organization={connectState.context.organization}
          apiMode={connectState.context.config.apiMode}
          usecasesLimit={connectState.context.config.useCaseLimit}
          onSubmit={({ provider, providerSearchString }) =>
            send({
              type: 'SELECT_PROVIDER',
              data: {
                providerSearchString,
                provider,
              },
            })
          }
        />
      );
    if (connectState.matches('dataAccessSelection')) {
      return (
        <SelectDataAccessComponent
          organization={connectState.context.organization}
          provider={assertNotNullable(connectState.context.provider)}
          onSubmit={({ isMeterSubmission }) =>
            send({ type: 'SELECT_ACCESS_METHOD', data: isMeterSubmission })
          }
        ></SelectDataAccessComponent>
      );
    }
    if (connectState.matches('credentialsMachine') && credentialsMachine)
      return <CredentialsScreens credentialsMachineRef={credentialsMachine} />;

    throw new Error('Unknown state');
  }, [connectState, send, credentialsMachine]);

  return (
    <ConnectContainer
      title={title}
      previous={previous}
      testId={`connect-screens-${config.correlationId}`}
    >
      {renderScreen()}
    </ConnectContainer>
  );
};

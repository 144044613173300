import { FlexCrate } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { ContentSectionFullHeight } from '@client/components/connect-container';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas-lite';
import { Organization, Provider } from '@schema/schemas';
import { LoadingAnimationStyle, Copy } from './verifying-credentials.style';

export type Props = {
  status?: string;
  organization: Organization;
  provider: Provider;
  complete: boolean;
};

export const VerifyingCredentials = ({
  provider,
  complete,
  organization,
  status,
}: Props): JSX.Element => {
  const { t: verifyingCopy } = useTranslation('connect', { keyPrefix: 'components.verifiying' });
  const { t: conclusionCopy } = useTranslation('connect', { keyPrefix: 'components.conclusion' });
  const orgName = organization.displayName || organization.name;

  const { rive, RiveComponent } = useRive({
    src: '/assets/animations/connect-loader.riv',
    stateMachines: 'State Machine 1',
    autoplay: true,
  });
  // Access the 'isLoaded' input
  const isLoadedInput = useStateMachineInput(rive, 'State Machine 1', 'isLoaded');

  // Set the 'isLoaded' input to true when complete prop is set to true
  if (complete && isLoadedInput) {
    isLoadedInput.value = true;
  }

  const getConclusionText = (): string => {
    if (provider.isThirdPartyPortalSupported) return conclusionCopy('thirdPartyPortal');
    if (status === 'LOGIN_SUCCESS') {
      return conclusionCopy('loginSuccess', { providerName: provider.name });
    }
    return conclusionCopy('loginTimedOut', { providerName: provider.name, orgName });
  };

  const copy = complete
    ? getConclusionText()
    : verifyingCopy('verifiyingText', { providerName: provider.name });

  return (
    <ContentSectionFullHeight
      centerContentVertically
      centerContentHorizontally
      dataAttributes={{ testid: 'verifying-credentials' }}
    >
      <FlexCrate gap="32px" flexDirection="column" alignItems="center">
        <RiveComponent style={LoadingAnimationStyle} data-testid="rive-animation" />
        <Copy>{copy}</Copy>
      </FlexCrate>
    </ContentSectionFullHeight>
  );
};

import { useTranslation } from 'react-i18next';
import { Link, Text } from '@arcadiapower/shrike';
import { Provider } from '@schema/schemas';
import { trackEvent, TrackEvents } from '@client/utils/analytics';
import { ForgotPasswordLinkWrapper } from './forgot-password-link.style';

export type Props = {
  provider: Provider;
};

export const ForgotPasswordLink = ({ provider }: Props): JSX.Element | null => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.forgotPasswordLink' });
  // Hide if there is no link or if this is a third-party-portal utility
  //
  // Third party portal utilities do not have username/password, so this
  // component does not make sense
  if (!provider.website || provider.isThirdPartyPortalSupported) return null;
  return (
    <ForgotPasswordLinkWrapper gap="8px" flexDirection="column" alignItems="center">
      <Text textStyle="paragraph500">{t('description')}</Text>
      <Text>
        <Link
          color="blue"
          href={provider.website}
          onClick={() => trackEvent(TrackEvents.PASSWORD_RESET_LINK_CLICKED)}
        >
          {t('linkLabel', { providerName: provider.name })}
        </Link>
      </Text>
    </ForgotPasswordLinkWrapper>
  );
};

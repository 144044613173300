import { assign, createMachine, spawn } from 'xstate';
import { stop } from 'xstate/lib/actions';
import { assertNotNullable, Organization } from '@arc-connect/schema';
import { TRPCClient } from '@client/utils/trpc';
import { Config } from '@client/types/config';
import { trackEvent, TrackEvents } from '@client/utils/analytics';
import { ConnectContext, ConnectEvents, ConnectServices } from './types';
import { credentialsMachine } from '../credentials/credentials';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const connectMachine = ({
  client,
  organization,
  config,
}: {
  client: TRPCClient;
  organization: Organization;
  config: Config;
}) => {
  return createMachine(
    {
      id: 'connect',
      predictableActionArguments: true,
      tsTypes: {} as import('./connect.typegen').Typegen0,
      schema: {
        context: {} as ConnectContext,
        services: {} as ConnectServices,
        events: {} as ConnectEvents,
      },
      context: {
        acquisitionTemplate: config.providerDetails?.acquisitionTemplate,
        acquisitionTemplateError: undefined,
        credentialsMachine: undefined,
        providerSearchString: undefined,
        provider: config.providerDetails?.provider,
        credentials: undefined,
        challengeStatus: undefined,
        organization,
        config,
        isMeterSubmission: false,
      },
      initial: 'initializing',
      states: {
        initializing: {
          entry: ['sendOnStart'],
          always: [
            { target: 'credentialsMachine', cond: 'shouldSkipProvider' },
            { target: 'provider' },
          ],
        },
        provider: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SELECT_PROVIDER: {
                  actions: ['saveProvider', 'setIsMeterSubmission'],
                  target: 'fetchingAcquisitionTemplate',
                },
              },
            },
            fetchingAcquisitionTemplate: {
              invoke: {
                id: 'fetchAcquisitionTemplate',
                src: 'fetchAcquisitionTemplate',
                onError: {
                  actions: ['saveAcquisitionTemplateError'],
                  target: 'idle',
                },
                onDone: {
                  actions: ['saveAcquisitionTemplate'],
                  target: 'transition',
                },
              },
            },
            transition: {
              always: [
                { target: '#connect.dataAccessSelection', cond: 'shouldShowDataAccessSelection' },
                { target: '#connect.credentialsMachine' },
              ],
            },
          },
        },
        dataAccessSelection: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SELECT_ACCESS_METHOD: {
                  actions: ['saveIsMeterSubmission'],
                  target: '#connect.credentialsMachine',
                },
                PREVIOUS: {
                  target: '#connect.provider',
                },
              },
            },
          },
        },
        credentialsMachine: {
          entry: 'startCredentialsMachine',
          on: {
            PREVIOUS: [
              {
                actions: 'clearCredentials',
                cond: 'shouldShowDataAccessSelection',
                target: '#connect.dataAccessSelection',
              },
              {
                actions: 'clearCredentials',
                target: 'provider',
              },
            ],
            RESELECT_PROVIDER: {
              target: 'provider',
              actions: ['stopCredentialsMachine', 'clearCredentialsMachine'],
            },
            CREDENTIALS_UPDATED: {
              actions: 'saveCredentials',
            },
            CREDENTIALS_DONE: {
              actions: ['saveChallengeStatus', 'sendOnEnd'],
              type: 'final',
            },
          },
          exit: ['stopCredentialsMachine', 'clearCredentialsMachine'],
        },
      },
    },
    {
      actions: {
        clearCredentials: assign(_context => ({
          credentials: undefined,
        })),
        stopCredentialsMachine: stop('credentialsMachine'),
        clearCredentialsMachine: assign(_context => ({
          credentialsMachine: undefined,
        })),
        saveProvider: assign((_context, event) => ({
          provider: event.data.provider,
          providerSearchString: event.data.providerSearchString,
          acquisitionTemplateError: undefined,
        })),
        saveCredentials: assign((_context, event) => ({
          credentials: event.data.credentials,
        })),
        saveChallengeStatus: assign((_context, event) => ({
          challengeStatus: event.data.challengeStatus,
        })),
        saveAcquisitionTemplateError: assign((_context, event) => ({
          acquisitionTemplateError: event.data,
        })),
        saveAcquisitionTemplate: assign((_context, event) => ({
          acquisitionTemplate: event.data,
        })),
        setIsMeterSubmission: assign(_context => ({
          isMeterSubmission:
            !!_context.provider?.isIntervalsThirdPartyPortalSupported &&
            (_context.organization.isIntervalsOnly || _context.config.useCaseLimit === 'intervals'),
        })),
        saveIsMeterSubmission: assign((_context, event) => ({
          isMeterSubmission: event.data,
        })),
        startCredentialsMachine: assign(context => ({
          credentialsMachine: spawn(
            credentialsMachine({
              client,
              provider: assertNotNullable(context.provider),
              acquisitionTemplate: assertNotNullable(context.acquisitionTemplate),
              organization: context.organization,
              config: { ...context.config, isMeterSubmission: context.isMeterSubmission },
            }),
            { sync: true }
          ),
        })),
        sendOnStart: context => {
          config?.callbacks?.onStart();
          let mode = 'create';
          if (context.config.updateTokenInfo) {
            mode = 'update';
          } else if (context.config.refreshTokenInfo) {
            mode = 'refresh';
          }
          trackEvent(TrackEvents.CONNECT_INITIALIZED, { mode });
        },
        sendOnEnd: context => {
          const { config, challengeStatus } = context;
          config.callbacks?.onEnd({ status: challengeStatus || 'PENDING' });
          trackEvent(TrackEvents.CONNECT_COMPLETED, {
            challengeStatus: challengeStatus || 'PENDING',
          });
        },
      },
      services: {
        fetchAcquisitionTemplate: async context => {
          return client.provider.getAcquisitionTemplate.query({
            providerId: assertNotNullable(context.provider).id,
            providerName: assertNotNullable(context.provider).name,
          });
        },
      },
      guards: {
        shouldSkipProvider: context => !!context.config.providerDetails,
        shouldShowDataAccessSelection: context =>
          !!context.organization.isStatementsAndIntervals &&
          !!context.provider?.isIntervalsThirdPartyPortalSupported &&
          !context.config.useCaseLimit,
      },
    }
  );
};
